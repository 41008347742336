import React from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Layout, Text, View } from '../../components/new';
import { firebaseEventLogger } from '../../utilities/firbaseAnalytics';
import { getTheme } from '../../themes/new/theme';
import CustomLottieView from '../../components/new/custom/CustomLottieView';

interface RenderWidgetProps {
  onPress: () => void;
  lottie: any;
  text: string;
  style?: StyleProp<ViewStyle>;
}
const RenderWidget = (props: RenderWidgetProps) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      activeOpacity={0.8}
      style={props?.style ? props.style : {}}
    >
      <View
        style={{
          padding: 16,
          flexDirection: 'column',
          alignItems: 'center',
        }}
        bg="white"
        borderRadius="md"
        shadow="lg"
        weight="medium"
      >
        <CustomLottieView
          style={{ width: 100, height: 100 }}
          autoPlay
          loop
          source={props.lottie}
          containerWidth={100}
        />
        <Text size="md" color="primary.300" mt="2xl" weight="medium">
          {props.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const StaffHomeScreen = () => {
  const navigation = useNavigation<any>();

  return (
    <Layout level={2} edges={['top']}>
      <View my="2xl" p="2xl" flexDirection="row" justifyContent="center">
        <Text size="xl" color="primary.500" weight="medium">
          Select a task
        </Text>
      </View>
      <View px="2xl" style={{ flexDirection: 'column' }}>
        <RenderWidget
          onPress={() => {
            firebaseEventLogger('account__videoMoments_Tap', {
              buttonName: 'videoMoments',
              screenName: 'account',
              userType: 'VideoSupervisor',
              interactionType: 'tap',
              isLoggedIn: true,
            });
            navigation.navigate('VideoMoments');
          }}
          lottie={require('../../../assets/lottie/animation2.json')}
          text="Record 32nd Moments"
        />
        {/* <RenderWidget
          style={{ marginTop: getTheme().space['2xl'] }}
          onPress={() => {
            firebaseEventLogger('account__printMoments_Tap', {
              buttonName: 'printMoments',
              screenName: 'account',
              userType: 'VideoSupervisor',
              interactionType: 'tap',
              isLoggedIn: true,
            });
            navigation.navigate('PrintMoments');
          }}
          lottie={require('../../../assets/lottie/animation1.json')}
          text="Print 32nd Moments"
        /> */}
      </View>
    </Layout>
  );
};

export default StaffHomeScreen;
