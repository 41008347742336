import React, { useState } from 'react';
import { useWindowDimensions } from 'react-native';
import * as FileSystem from 'expo-file-system';
import * as MediaLibrary from 'expo-media-library';
import { View, Text, Button } from '../../components/new';
import AutoBottomSheet from '../../components/new/custom/AutoBottomSheet';
import CachedImage from '../../components/new/custom/CachedImage';
import { imageTransformation } from '../../utilities/helper';
import photoMoments from '../Strings';
import { Appearance, State } from '../../themes/new/helper';
import {
  SnackbarStatus,
  SnackbarVersion,
} from '../../components/new/primitive/snackbar/helpers/helpers';
import { useSnackbarStore } from '../../stores/snackbar/snackbarStore';

const PreviewImageBottomSheet = ({ bottomSheetModalRef, assetId }) => {
  const { width, height } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const { dispatchSnackbar } = useSnackbarStore((state) => state);

  const [permissionResponse, requestPermission] = MediaLibrary.usePermissions();
  const saveFile = async ({ fileUri }) => {
    const { status } = await requestPermission();
    if (status === 'granted') {
      try {
        const asset = await MediaLibrary.createAssetAsync(fileUri);
        const album = await MediaLibrary.getAlbumAsync('32nd');
        if (album == null) {
          await MediaLibrary.createAlbumAsync('32nd', asset, true);
        } else {
          MediaLibrary.addAssetsToAlbumAsync([asset], album.id, true);
        }
        setLoading(false);
        await FileSystem.deleteAsync(fileUri);
        dispatchSnackbar({
          msg: photoMoments.imageDownloaded,
          status: SnackbarStatus.success,
          version: SnackbarVersion.v2,
        });
      } catch (error) {
        console.log('eeeee-', error);
        setLoading(false);
        dispatchSnackbar({
          msg: `Oops, Something went wrong!`,
          status: SnackbarStatus.error,
          version: SnackbarVersion.v2,
        });
      }
    }
  };
  const handleDownload = async () => {
    try {
      setLoading(true);
      const assetLink = `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`;
      let fileUri = `${FileSystem.documentDirectory}/${assetId}`;
      fileUri = fileUri.replace(' ', '');
      const res = await FileSystem.downloadAsync(assetLink, fileUri);
      await saveFile({ fileUri: res.uri });
    } catch (err) {
      setLoading(false);
    }
  };
  return (
    <AutoBottomSheet bottomSheetRef={bottomSheetModalRef}>
      <View>
        <View px="2xl" mt="4xl">
          <Text
            size="3xl"
            color="primary.500"
            textAlign="center"
            weight="medium"
          >
            {photoMoments.preview}
          </Text>
          <Text size="md" color="primary.200" textAlign="center" pt="md">
            {photoMoments.confirmPreview}
          </Text>
          <CachedImage
            style={[
              {
                width: width - 32,
                height: height * 0.6,
                justifyContent: 'center',
                alignItems: 'center',
                alignSelf: 'center',
                marginTop: 24,
                resizeMode: 'contain',
              },
            ]}
            source={{
              uri: imageTransformation(
                `https://ik.imagekit.io/32ndhub/photoMoment/${assetId}`,
                (width - 32) * 0.8,
                `${(width - 32) * 0.8}-${height * 0.6}`,
              ),
            }}
          />
          <View mt="4xl">
            <Button
              appearance={Appearance.FILLED}
              state={loading ? State.DISABLED : State.ACTIVE}
              size="md"
              onPress={handleDownload}
              loading={loading}
            >
              Download Image
            </Button>
          </View>
        </View>
      </View>
    </AutoBottomSheet>
  );
};

export default PreviewImageBottomSheet;
